<template>
 <PageHeading
  :has-meta-data="false"
  :has-action-button="false"
  :has-search="true"
  :title="$t('ticketsClosed')"
  @open="this.openForm = !this.openForm"
 />
 <div v-if="tickets.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <TicketClosedTable
      :tablePaginatedData="filteredTickets()"
      @sorting="sort($event)"
      :statuses="status"
      :searchPriority="this.searchPriority"
      :goToDetail="goToDetail"
      @getTickets="this.getTickets()"
      @updateTicketDetail="(...args) => this.getTicketDetail(...args)"
      @pushStatusSearch="this.pushSearchStatus($event)"
      :searchStatus="this.searchStatus"
      @clearStatusFilter="this.searchStatus.length = 0"
      @pushPrioritySearch="this.pushSearchPriority($event)"
      @clearPriorityFilter="this.searchPriority.length = 0"
      @activeBanner="$emit('activeBanner'), getTickets()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('ticketsClosed') })"
   :subtitle="$t('emptyStateSubtitle')"
  />
 </div>
 <!--  <div v-if="this.ticketId === null" class="flex flex-col">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full px-5">
    <div class="shadow overflow-hidden border-b border-gray-200 rounded-md">
     <TablePagination
      :tableData="filteredTickets()"
      @sort="sort($event)"
      @activeBanner="(activeBanner = true), getTickets(), (key = !key)"
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
      @updateTicketDetail="(...args) => this.getTicketDetail(...args)"
      :statuses="status"
      @pushStatusSearch="this.pushSearchStatus($event)"
      :searchStatus="this.searchStatus"
      @clearStatusFilter="this.searchStatus.length = 0"
      :searchPriority="this.searchPriority"
      @pushPrioritySearch="this.pushSearchPriority($event)"
      @clearPriorityFilter="this.searchPriority.length = 0"
      @getTickets="this.getTickets()"
     />
    </div>
   </div>
  </div>
 </div> -->
</template>

<script>
import axios from "axios";

import TicketClosedTable from "../components/TicketClosedTable.vue";
import EmptyState from "../components/EmptyState.vue";

import TablePagination from "../components/TablePagination.vue";
import TicketDetail from "../components/TicketDetail";
import TicketDetailNew from "../components/TicketDetailNew";
import OpenTicket from "../components/OpenTicket.vue";
import SuccessBanner from "../components/SuccessBanner.vue";

import PageHeading from "../components/PageHeading.vue";

const account = localStorage.getItem("account");

const priorities = [
 { id: 1, name: "basse" },
 { id: 2, name: "normale" },
 { id: 3, name: "haute" },
 { id: 4, name: "critique" },
];

export default {
 name: "Tickets",
 props: ["search", "closeTicketDetail"],
 components: {
  EmptyState,
  TicketClosedTable,
  TicketDetail,
  TicketDetailNew,
  TablePagination,
  OpenTicket,
  SuccessBanner,
  PageHeading,
 },
 data() {
  return {
   tickets: [],
   ticketId: null,
   statusName: null,
   currentSort: "id",
   currentSortDir: "asc",
   account,
   searchDate: "",
   searchStatus: [],
   searchPriority: [],
   searchDateUpdate: "",
   status: [],
   openForm: false,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   priorities,
  };
 },
 methods: {
  pushSearchStatus(status) {
   if (!this.searchStatus.includes(status)) {
    this.searchStatus.push(status);
   } else {
    let index = this.searchStatus.indexOf(status);
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
   this.$forceUpdate();
  },
  pushSearchPriority(priority) {
   if (!this.searchPriority.includes(priority)) {
    this.searchPriority.push(priority);
   } else {
    let index = this.searchPriority.indexOf(priority);
    if (index > -1) {
     this.searchPriority.splice(index, 1);
    }
   }
   this.$forceUpdate();
  },
  checkPriority(id) {
   for (let index = 0; index < this.priorities.length; index++) {
    if (this.priorities[index].id == id) {
     return this.priorities[index].name;
    }
   }
  },
  async getTickets() {
   if (localStorage.getItem("token")) {
    this.$emit("setLoading", true);
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/tickets?customerAccount=${account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     this.tickets = this.orderArray(res.data);
     this.tickets = this.filterClosedTickets(this.tickets);
     this.$emit("tickesCount", this.filterOpenTickets(res.data).length, this.tickets.length);
     console.log("file: Tickets.vue ~ line 185 ~ getTickets ~ res.data", res.data);
    } catch (error) {
     this.errorHandling(error);
    }
    this.$emit("setLoading", false);
   }
  },
  orderArray(arr) {
   return arr.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
   });
  },
  filterOpenTickets(data) {
   return data.filter((res) => {
    return res.open;
   });
  },
  filterClosedTickets(data) {
   return data.filter((res) => {
    return !res.open;
   });
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    dateCreated.getHours() +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
  checkStatus(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].name;
     }
    }
   }
  },
  checkStatusColor(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].color;
     }
    }
   }
  },
  sortedTickets() {
   return this.tickets.sort((a, b) => {
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    return 0;
   });
  },
  numberIsEqual(numb) {
   return String(numb).includes(String(this.search));
  },
  filteredTickets() {
   return this.sortedTickets().filter((ticket) => {
    if (
     (this.searchStatus.length > 0 && !this.searchPriority.length > 0) ||
     (this.searchPriority.length > 0 && !this.searchStatus.length > 0)
    ) {
     let status = this.checkStatus(ticket.status_id);
     let priority = this.checkPriority(ticket.priority_id);
     if (this.search) {
      return (
       ticket.id == this.search ||
       ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
       this.formatDate(ticket.created_at).toLowerCase().includes(this.search.toLowerCase()) ||
       this.formatDate(ticket.updated_at).toLowerCase().includes(this.search.toLowerCase())
      );
     }

     return this.searchStatus.includes(status) || this.searchPriority.includes(priority);
    } else if (
     (this.searchStatus.length > 0 && this.searchPriority.length > 0) ||
     (this.searchPriority.length > 0 && this.searchStatus.length > 0)
    ) {
     let status = this.checkStatus(ticket.status_id);
     let priority = this.checkPriority(ticket.priority_id);
     if (this.search) {
      return (
       ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
       ticket.id == this.search ||
       this.formatDate(ticket.created_at).toLowerCase().includes(this.search.toLowerCase()) ||
       this.formatDate(ticket.updated_at).toLowerCase().includes(this.search.toLowerCase())
      );
     }
     return this.searchStatus.includes(status) && this.searchPriority.includes(priority);
    } else if (this.search) {
     return (
      ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
      ticket.id == this.search ||
      this.formatDate(ticket.created_at).toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(ticket.updated_at).toLowerCase().includes(this.search.toLowerCase())
     );
    }
    return ticket;
   });
  },
  async getStatus() {
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.status = res.data;
  },
  getTicketDetail(...args) {
   this.ticketId = args[0];
   this.statusName = args[1];
   this.$router.push(`/ticket/${this.ticketId}`);

   this.$forceUpdate();
  },
 },
 mounted() {
  this.getTickets();
  this.getStatus();
 },
 watch: {
  closeTicketDetail: function (val) {
   this.ticketId = null;
  },
 },
};
</script>

<style></style>
